import { useForm } from 'react-hook-form';
import { User } from 'src/app/common/types/User';
import { useFetcher } from 'src/app/api/fetcher';
import { useChannel } from '../user.hooks';
import { doFetchUser } from '../user.actions';
import { useDispatch } from 'react-redux';

type Props = {
  user: User;
};

export default function UsernameForm({ user }: Props) {
  const dispatch = useDispatch();
  const channel = useChannel();
  const fetcher = useFetcher();
  const currentUsername =
    user?.profile?.usernamesByChannelUniqueName?.[channel.cleanname] ||
    user?.profile?.uid ||
    'Unknown user';

  const { register, handleSubmit, errors, setError, formState } = useForm({
    defaultValues: {
      username: currentUsername,
    },
  });
  const { dirty, isSubmitting, isSubmitted } = formState;

  const onSubmit = async (data) => {
    // don't submit the form if the user hasn't changed their name
    if (data.username === currentUsername) {
      setError('username', 'conflict', "That's already your username!");
      return;
    }
    try {
      const res = await fetcher(
        '/user',
        {
          body: {
            channelIdentifier: channel.cleanname,
            username: data.username,
          },
          headers: [],
        },
        'PUT'
      );

      if (res.status === 409) {
        setError(
          'username',
          'conflict',
          'Sorry, that username is already in use.'
        );
      }

      if (res.ok) {
        dispatch(doFetchUser());
      }
    } catch (e) {
      console.error(e);
      setError('username', 'Sorry, there was a problem setting your username.');
    }
  };

  return (
    <div className="">
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          className="bg-bg-secondary text-base px-2 py-1 border mr-2 rounded focus:outline-none focus:bg-white hover:bg-gray-100 cursor-pointer"
          name="username"
          id="username"
          placeholder="Set a username"
          ref={register({
            required: true,
            pattern: {
              value: /^[A-Za-z0-9_-]+$/,
              message:
                'Usernames can contain only letters, numbers, underscores and dashes.',
            },
          })}
        />

        {dirty && (
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Saving...' : 'Update'}
          </button>
        )}

        {errors.username && (
          <p className="text-red-500 m-0">
            {/* Username required, must contain only letters and numbers, _ or -. */}
            {errors?.username?.message}
          </p>
        )}

        {/* {hasError && <p className="text-red-500 m-0">{hasError}</p>} */}
        {isSubmitted && !errors.username && !isSubmitting && (
          <p className="text-green-500 m-0">Username saved.</p>
        )}
      </form>
    </div>
  );
}
