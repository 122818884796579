import Cookies from 'js-cookie';
import { BASE_URL } from '.';
import { useChannel } from 'src/app/user/user.hooks';
import { TOKEN } from 'src/app/user/user.constants';

const localStorageKey = 'SP_STATE';

function createFetcher(uniqueName: string) {
  // @ts-ignore
  return function fetcher(
    endpoint,
    // @ts-ignore
    { body, ...customConfig } = { headers: [] },
    method?: string
  ) {
    const token =
      JSON.parse(window.localStorage.getItem(localStorageKey))?.user?.token ||
      Cookies.get(TOKEN);
    const headers = { 'content-type': 'application/json' };
    if (token) {
      headers['Authorization'] = `${token}`;
    }
    const config = {
      method: body ? (method ? method : 'POST') : 'GET',
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig?.headers,
        'Channel-UniqueName': uniqueName,
      },
    };
    if (body) {
      config['body'] = JSON.stringify(body);
    }
    return window
      .fetch(`${BASE_URL}${endpoint}`, config)
      .then(async (response) => {
        if (response.status === 401) {
          // logout();
          //@ts-ignore
          window.location.assign(window.location);
          return;
        }
        if (response.status === 409) {
          return response;
        }
        const data = await response.json();
        if (response.ok) {
          return { ...data, status: response.status, ok: response.ok };
        } else {
          return Promise.reject(data);
        }
      });
  };
}

export function useFetcher() {
  const channel = useChannel();
  return createFetcher(channel.cleanname);
}
